import React, { useState, useEffect } from 'react';
import Skinview3d from 'react-skinview3d';
import SearchField from './SerchField';
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import Grid from '@material-ui/core/Grid';
import './App.css';

import theme from './theme';

const App = () => {
  const [ username, setUsername ] = useState('Haka');

  const handleSkinChange = (name) => {
    setUsername(name);
  }

  useEffect(() => {
   
  }, [])

  return (
    <div className="App">
      <Grid className="App-header">
        <h1 class="heading">Minecraft Skin Search</h1>
        <ThemeProvider theme={theme}>
          <SearchField
            onSubmit={handleSkinChange}
          />
          <Skinview3d
            skinUrl={`https://minotar.net/skin/${username}`}
            width="700"
            height="700"
          />
        </ThemeProvider>
        <p>Follow for Minecraft Memes and News on <a class="App-link" href="https://www.facebook.com/plugins/error/confirm/like?iframe_&kid_directed_site=false&secure=true&plugin=like&return_params=%7B%22action%22%3A%22like%22%2C%22app_id%22%3A%22110898872300039%22%2C%22channel%22%3A%22https%3A%2F%2Fstaticxx.facebook.com%2Fx%2Fconnect%2Fxd_arbiter%2F%3Fversion%3D46%23cb%3Df1cf6e84b53f9f%26domain%3Dwww.mcskinsearch.com%26origin%3Dhttps%253A%252F%252Fwww.mcskinsearch.com%252Ff391d48c3f24d14%26relation%3Dparent.parent%22%2C%22container_width%22%3A%220%22%2C%22href%22%3A%22http%3A%2F%2Fwww.facebook.com%2FMinecraftMemesNews%22%2C%22layout%22%3A%22standard%22%2C%22locale%22%3A%22en_US%22%2C%22sdk%22%3A%22joey%22%2C%22share%22%3A%22false%22%2C%22show_faces%22%3A%22false%22%2C%22width%22%3A%22290%22%2C%22ret%22%3A%22sentry%22%2C%22act%22%3A%22like%22%7D" rel="noopener">Facebook</a></p>
      </Grid>
    </div>
  );
}

export default App;
