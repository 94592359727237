import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';

const styles = {
  root: {
    background: '#212121',
    padding: '2px 12px 2px 12px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    color: "white",
    fontSize: 42,
  },
  wrap: {
    width: '100%',
    padding: '12px 0 12px 0'
  },
  iconButton: {
    color: '#fff',
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
};

const SearchField = (props) => {
  const [ textValue, setTextValue ] = useState('');
  const { classes } = props;

  const handleValue = (e) => {
    console.log(e.target.value);
    setTextValue(e.target.value);
  }
  const keyPress = (e) => {
    if(e.keyCode === 13){
      console.log('value', e.target.value);
      props.onSubmit(e.target.value)
    }
  }

  return (
    <Paper className={classes.root}>
      { textValue && (
        <IconButton onClick={() => setTextValue('')} className={classes.iconButton} size="medium" aria-label="clear">
          <ClearIcon />
        </IconButton>
      )}
      <InputBase
        autoCapitalize={false}
        autoComplete={false}
        autoCorrect={false}
        className={classes.input}
        onKeyDown={keyPress}
        value={textValue}
        placeholder="Username"
        inputProps={{ 'aria-label': 'Username' }}
        onChange={handleValue}
      />
      <IconButton onClick={() => props.onSubmit(textValue)} className={classes.iconButton} size="medium" aria-label="search">
        <SearchIcon />
      </IconButton>
    </Paper>
  )
};

export default withStyles(styles)(SearchField);